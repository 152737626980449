import React, { useEffect, useState } from "react";
import Modal from "@amzn/meridian/modal"
import { AfnResponseType, AfnScannableResultsComponentPropsType, getItemsType } from "src/pages/visibility-page/components/afn/afn.component.type";
import { AfnNodeDataType, bulkItemDetailsResponseType } from "src/common/dao/afn/afn-dao.type";
import { TableData } from "src/components/helpers/table-renderer-util/table-renderer-util.type";
import { DetailsRendererUtil } from "src/components/helpers/details-renderer-util/details-renderer-util";
import { ToolLinksRenderer } from "src/components/helpers/tool-links-renderer-util/tool-links-renderer-utl";
import { TableRendererUtil, handleDownloadFileBtnClick } from "src/components/helpers/table-renderer-util/table-renderer-util";
import { getRealmFromUrl } from "src/utils/utils";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Loader from "@amzn/meridian/loader";
import Theme from "@amzn/meridian/theme";
import "../afn.component.scss"
import { getDownloadDataBtn } from "./afn-table-results.component";
import { bulkItemDetailsApi, itemDetailsApi } from "src/common/api/afn.api";
import { getItemDetailsResponseType } from "src/common/dao/afn/afn-dao.type";
import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import downloadSmallIcon from "@amzn/meridian-tokens/base/icon/download-small"
import { apiFatalsMetricsPublisher, apiSuccessMetricsPublisher, buttonClickMetricsPublisher } from "src/utils/metricUtils";
import { ModalUtil } from "src/components/helpers/modal-util/modal-util";
import { UserGuideModal } from "src/components/helpers/user-guide-modal/user-guide-modal";
import { GET_ALL_ITEMS_USER_GUIDE } from "../afn.config";
import { HideForAftxUsers } from "src/utils/aftx-utils";


export const AfnLegResultsComponent = ({ afnResults, afnForm, showActionBarComponentsForTable, tableResponseForExport, idsMap, handleSubmit}: AfnScannableResultsComponentPropsType) => {
    const getTableData = (nodeData: Array<AfnNodeDataType>) => {
        const tableData: Array<Array<TableData>> = [];
        {
            Object.entries(nodeData[0]).map(([key, cellInfo]) => {
                const tableRecord: Array<TableData> = []
                if (cellInfo.isVisible !== false) {
                    tableRecord.push({
                        value: cellInfo.label,
                        type: "header",
                        styles: { fontSize: '12px', wordWrap: 'break-word' }
                    })
                }
                {
                    nodeData.map((currentPackage: AfnNodeDataType) => {
                        Object.entries(currentPackage).map(([keys, cellInfoLocal]) => {
                            if (cellInfoLocal.label === cellInfo.label && cellInfo.isVisible !== false) {
                                tableRecord.push({
                                    value: cellInfoLocal.value ? cellInfoLocal.value : "",
                                    type: cellInfo.type == undefined ? 'text' : cellInfo.type
                                })
                            }
                        })
                    })
                }
                tableData.push(tableRecord)
            })
        }
        return tableData
    }
    const bulkItemDetailsInitialState: bulkItemDetailsResponseType = {
        bulkItemDetailsTableHeaders: [],
        bulkItemDetailsData: [],
        error: undefined
    }
    const [isLoading, setIsLoading] = useState(false);
    const [bulkItemDetailsResult, setBulkItemDetailsResult] = useState<bulkItemDetailsResponseType>(bulkItemDetailsInitialState)
    const [isBulkItemDetailsFetched, setIsBulkItemDetailsFetched] = useState<boolean>(false);
    const [isBulkItemDetailsModalOpen, setIsBulkItemDetailsModalOpen] = useState<boolean>(false);
    const onClose = () => setIsBulkItemDetailsModalOpen(false)
    const [loadedIndex, setLoadedIndex] = useState(-1);
    const [loadedItemsDetailsMap, setLoadedItemsDetailsMap] = useState<getItemsType>({ "-1": [] })
    const [itemDetailsFetched, setItemDetailsFetched] = useState<{ [key: number]: boolean }>({ 0: false })
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [imageURL, setImageURL] = useState<string>("")
    // Clearing the loaded details upon new search 
    useEffect(() => {
        setLoadedItemsDetailsMap({ "-1": [] })
    }, [afnResults])

    // Need to make the scannable id type to be only string
    // This function checks for the item details in the map and during a bulk search will be fetching the
    // details and return it based on the index


    const getItemDetailsFromMap = (key: number): Array<getItemDetailsResponseType> | null => {
        if (loadedItemsDetailsMap.hasOwnProperty(key))
            return loadedItemsDetailsMap[key]
        return null
    }

    const handleShowImage = (image: any) => {
        setIsModalOpen(true)
        setImageURL(image)
    }

    const renderMultiLineText = (msg: Array<getItemDetailsResponseType>): JSX.Element => {
        return (
            <div className="get_items_data" >
                {msg.map((item, index) => (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <Text type="b100" key={index}>{item.itemDetails}</Text>
                        {item.imageURL &&
                            <Button size="small" type="primary" onClick={() => handleShowImage(item.imageURL)}>show image</Button>}
                        <ModalUtil openModal={isModalOpen} title="item image" setOpenModal={(val: boolean) => setIsModalOpen(val)}>
                            <img src={imageURL} width="500" height="500" alt="No image found" />
                        </ModalUtil>
                    </div>
                ))}
            </div>
        );


    };

    const getBulkSearchButton = () => {
        setIsBulkItemDetailsModalOpen(true)
        buttonClickMetricsPublisher.publishCounterMonitor("ALL-GET-ITEMS-SEARCH", 1)
        if (!isBulkItemDetailsFetched) {
            bulkItemDetailsApi(idsMap).then((response: bulkItemDetailsResponseType | any) => {
                apiSuccessMetricsPublisher.publishCounterMonitor("ALL-GET-ITEMS-SUCCESS", 1)
                setBulkItemDetailsResult({
                    bulkItemDetailsTableHeaders: response.bulkItemDetailsTableHeaders,
                    bulkItemDetailsData: response.bulkItemDetailsData,
                    error: response.error,
                    failedIdsList: response.failedIdsList ?? []
                })
                setIsBulkItemDetailsFetched(true)
            }).catch((error: any) => {
                apiFatalsMetricsPublisher.publishCounterMonitor("ALL-GET-ITEMS-FAILURE", 1)
                setBulkItemDetailsResult({
                    bulkItemDetailsTableHeaders: [],
                    bulkItemDetailsData: [],
                    error: "Received error while fetching item details" + error
                })
            })
        }
    };

    const createBulkGetItemAndDownloadButtons = (tableResponseForExport: AfnResponseType) => {
        return <div style={{ margin: "2% 0 -1% 0", display: 'flex' }}>
            <>
                {
                    ["scannable", "shipment", "transshipment", "container"].includes(afnForm.shipmentType) ?
                        <Row>
                            <Button size="small"
                                onClick={() => {
                                    getBulkSearchButton()

                                }}>
                                Get All Items
                            </Button> 
                        </Row> : <></>}
                {isBulkItemDetailsModalOpen && (<HideForAftxUsers> <UserGuideModal {...GET_ALL_ITEMS_USER_GUIDE} /> </HideForAftxUsers>)}
            </>
            <Row width="100%" alignmentHorizontal="end" className="actions" >

                <Button size="small"
                    onClick={() => {
                        const table: any = tableResponseForExport.table;
                        // calling handle submit to fetch the results again with manifest details and from there
                        // will be trigerring this function to download the csv
                        handleSubmit(true)
                    }}>
                    <Icon tokens={downloadSmallIcon} />Export to CSV
                </Button>
            </Row>
        </div>

    }

    const getItems = (scannableId: string, index: number) => {
        setIsLoading(true)
        setLoadedIndex(index);
        itemDetailsApi(scannableId).then((response: Array<getItemDetailsResponseType> | any) => {
            apiSuccessMetricsPublisher.publishCounterMonitor("ITEM-DETAILS-SUCCESS", 1)
            setLoadedItemsDetailsMap((loadedItemsDetailsMap) => ({ ...loadedItemsDetailsMap, [index]: response }))
            setIsLoading(false)
            setItemDetailsFetched((itemDetailsFetched) => ({ ...itemDetailsFetched, [index]: true }))

        }).catch((error: any) => {
            setIsLoading(false)
            apiFatalsMetricsPublisher.publishCounterMonitor("ITEM-DETAILS-FAILURE", 1)
        })
    }

    return (
        <React.Fragment>
            {createBulkGetItemAndDownloadButtons(tableResponseForExport)}
            {
                <Modal
                    title="Item Details"
                    open={isBulkItemDetailsModalOpen}
                    onClose={onClose}
                    scrollContainer="viewport"
                    closeLabel="Close"
                    aria-describedby="modal-description"
                >
                    {!isBulkItemDetailsFetched ? <div>Loading <Loader type="circular" size="medium" /></div> : (bulkItemDetailsResult.error === undefined ? (
                        <div>
                            <div style={{ marginBottom: "5px", display: "flex" }}>
                                <Row width="100%" alignmentHorizontal="end" className="actions" >
                                    <Button size="small"
                                        onClick={() => {

                                            handleDownloadFileBtnClick(bulkItemDetailsResult.bulkItemDetailsTableHeaders, bulkItemDetailsResult.bulkItemDetailsData)
                                        }}>
                                        <Icon tokens={downloadSmallIcon} />Export to CSV
                                    </Button>
                                </Row>
                            </div>
                            <Box type="outline">
                                <div className="table-render-item">
                                    <TableRendererUtil
                                        tableData={bulkItemDetailsResult.bulkItemDetailsData}
                                        tableHeaders={bulkItemDetailsResult.bulkItemDetailsTableHeaders}
                                        onFiltersClick={() => { }}
                                        showActionBarComponents={{
                                            "showActionBar": false,
                                            "showSearchBar": false,
                                            "showFilters": false,
                                            "showDownloadButton": false,
                                            showColumnFilters: true
                                        }}
                                        numberOfPages={Math.ceil(bulkItemDetailsResult.bulkItemDetailsData.length / 10)}
                                        rowsPerPage={10}
                                        isRowHeader={true}

                                    ></TableRendererUtil>
                                </div>
                                {bulkItemDetailsResult.failedIdsList?.length ? <div>
                                    <Text><pre>Got exception while fetching item details for the below scannableIds.
                                        {bulkItemDetailsResult.failedIdsList}
                                    </pre></Text>
                                </div> : <></>}
                            </Box>
                        </div>
                    ) : <Text>{bulkItemDetailsResult.error}</Text>)
                    }
                </Modal>
            }
            {
                afnResults.map((currentPackage, index) => {
                    if (currentPackage.legInfo.Tid !== undefined && currentPackage.legInfo.Tid !== "recycled") {
                        const newTidUrl = "/?lang=EN&region=" + getRealmFromUrl().toLocaleUpperCase() + "&format=leg&nodeInfo=&shipmentType=scannable&searchId=" + currentPackage.legInfo.Tid;
                        return (
                            <div style={{ "marginTop": "2%" }} >
                                <Box type="outline" spacingInset={"200"} >
                                    <Theme tokens={{ boxBackgroundColorOutline: '#fba3b7' }}>
                                        <Box type="outline" spacingInset="200">
                                            <Text type="h100" alignment="center">
                                                {currentPackage.legInfo.displayString} <a href={newTidUrl} target="_blank">{currentPackage.legInfo.Tid}</a>
                                            </Text>
                                        </Box>
                                    </Theme>
                                </Box>
                            </div>
                        )
                    }

                    return (
                        <div data-cyid="afn-leg-results-comp" style={{ "marginTop": "2%" }}>
                            <Box type="outline" spacingInset={"300"} >
                                <Theme tokens={{ boxBackgroundColorOutline: currentPackage.legInfo.Tid === "recycled" ? '#8a8de8' : '#77c57f' }}>
                                    <Box type="outline" spacingInset="200">
                                        <Text type="h100" alignment="center">
                                            {currentPackage.legInfo.displayString}
                                        </Text>
                                    </Box>
                                </Theme>
                                <Column spacingInset={"200"} spacing={"200"}>
                                    {currentPackage.packageDetails && <DetailsRendererUtil detailsRendererUtilData={currentPackage.packageDetails!} />}
                                    {currentPackage.toolLinks && afnForm.shipmentType != "transshipment" && <ToolLinksRenderer toolLinksDataMap={currentPackage.toolLinks!} />}
                                    {currentPackage?.esmmInfo && currentPackage.enrichedTrackingIdInfo && currentPackage.packageDetails && <Box type="outline" spacingInset={"200"} >
                                        <table>
                                            {afnForm.shipmentType != "transshipment" && <tr>
                                                <td width="10%"><Text type="b100" alignment="left" className="section-header1">{currentPackage?.esmmInfo!.label}</Text></td>
                                                <td><Text type="b100" className="cell-data"> {currentPackage?.esmmInfo!.value}</Text></td>
                                            </tr>}
                                            {currentPackage.enrichedTrackingIdInfo!.isVisible && <tr>
                                                <td width="10%"><Text type="b100" alignment="left" className="section-header1">{currentPackage?.enrichedTrackingIdInfo!.label}</Text></td>
                                                <td><Text type="b100" className="cell-data"> {currentPackage?.enrichedTrackingIdInfo!.value}</Text></td>
                                            </tr>}
                                            <tr>
                                                <td width="10%"><Button size="small" data-cyid="get-items-btn"
                                                    type="primary" disabled={itemDetailsFetched[index]}
                                                    onClick={() => { getItems(String(currentPackage.packageDetails!.details.label.value), index) }}>{loadedIndex == index && isLoading ?
                                                        <> Loading <pre /> <Loader type="circular" size="small" /> </> :
                                                        "Get Items"}
                                                </Button>
                                                </td>
                                                <td><Text type="b100" className="cell-data">{renderMultiLineText(getItemDetailsFromMap(index) || [])}</Text></td>
                                            </tr>
                                        </table>
                                    </Box>}
                                    <Box type="outline">
                                        <div className="table-render">
                                            <TableRendererUtil
                                                tableData={getTableData(currentPackage.nodeDetails)}
                                                onFiltersClick={() => { }}
                                                tableHeaders={[]}
                                                showActionBarComponents={showActionBarComponentsForTable}
                                                numberOfPages={-1}
                                                onTablePageChange={() => { }}
                                                isRowHeader={true}
                                            />
                                        </div>
                                    </Box>
                                </Column>
                            </Box>
                        </div>
                    )
                })
            }
        </React.Fragment>
    )
}